/* eslint-disable react-hooks/rules-of-hooks */
import _ from 'lodash';
import { gql } from '@apollo/client';
import client, { runMutation, runQuery } from '../apollo-client';
import tracker from '../lib/tracker';
import sdkClient from '../lib/hmdsdkClient';
import {
  MetricService,
  CreateRequest as CreateMetricRequest,
  CreateOrUpdateRequest as CreateOrUpdateMetricRequest,
  GetRequest as GetMetricRequest,
  Metric,
} from '@hmd/sdk/api/analysis/metric/v1';
import {
  Expectation,
  ExpectationService,
  CreateRequest as CreateExpectationRequest,
  CreateOrUpdateRequest as CreateOrUpdateExpectationRequest,
  GetRequest as GetExpectationRequest,
} from '@hmd/sdk/api/analysis/expectation/v1';
import { object } from 'prop-types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const ANALYSIS_RUN_STATUSES = {
  PENDING: 'PENDING',
  PENDING_RETRY: 'PENDING_RETRY',
  RUNNING: 'RUNNING',
  DONE: 'DONE',
};

export function analysisInProgress(analysis) {
  const runStatus = _.get(analysis, 'runStatus');

  return (
    runStatus === ANALYSIS_RUN_STATUSES.PENDING ||
    runStatus === ANALYSIS_RUN_STATUSES.PENDING_RETRY ||
    runStatus === ANALYSIS_RUN_STATUSES.RUNNING
  );
}

export const FRAMEWORKS = {
  PIPER: 'PIPER',
  JUDY: 'JUDY',
  JUDITH: 'JUDITH',
  MANUAL: 'MANUAL',
};

const analysisFragment = `
  id
  name
  testPassed
  runStatus
  runRequestedAt
  runStartedAt
  runFinishedAt
  framework
  data
`;

const upsertAnalysisFragment = gql`
  mutation createOrUpdateAnalysis(
    $name: String
    $framework: Framework
    $runStatus: RunStatusChoice
    $testPassed: Boolean
    $robotLogName: String
  ) {
    createOrUpdateAnalysis(
      framework: $framework
      runStatus: $runStatus
      name: $name
      testPassed: $testPassed
      robotLogName: $robotLogName
    ) {
      id
      framework
      runStatus
      name
      testPassed
      createdById
      updatedById
    }
  }
`;

const runAnalyzersMutation = gql`
  mutation runAnalyzers(
    $robotLogId: UUID!,
    $analyzerNames: [String]!
  ) {
    runAnalyzers(robotLogId:$robotLogId,analyzerNames:$analyzerNames) {
      ${analysisFragment}
    }
  }
`;
const getAnalysesQuery = gql`
    query analyses($name: String, $robotLogId: UUID, $robotLogIds: [UUID], $analysisIds: [UUID]) {
        analyses(name:$name, robotLogId:$robotLogId, robotLogIds: $robotLogIds, analysisIds: $analysisIds) {
            edges {
                node {
                    ${analysisFragment}
                }
            }
        },
    }
`;

export function getAnalyses(variables, callback) {
  runQuery({
    variables,
    query: getAnalysesQuery,
    fetchPolicy: 'network-only',
    callback,
  });
}

export function runAnalyzers(variables, callback) {
  const { analyzerNames } = variables;

  analyzerNames.forEach((n) => tracker.trackEvent('Flight', 'AnalyzerRun', n));

  runMutation({
    variables,
    mutation: runAnalyzersMutation,
    callback,
  });
}

export function createOrUpdateAnalysis(variables, callback) {
  client
    .mutate({
      mutation: upsertAnalysisFragment,
      variables,
    })
    .then(
      (val) => {
        if (typeof callback === 'function') {
          callback(null, val);
        }
      },
      (err) => {
        if (typeof callback === 'function') {
          callback(err);
        }
      }
    );
}

export function getValueFromData({ data, key = 'value' }) {
  if (!data) {
    return;
  }

  let dataObj = {};
  try {
    if (_.isString) {
      dataObj = JSON.parse(data);
    } else if (_.isObject(data)) {
      dataObj = data;
    }
    if (!_.isUndefined(dataObj[key])) {
      return dataObj[key];
    }
    return;
  } catch (e) {
    return e.toString();
  }
}

export const createOrUpdateMetric = async (metric) => {
  const newMetric = new Metric();

  const CreateMetric = new CreateOrUpdateMetricRequest();
  newMetric.setName(metric.name);
  newMetric.setSessionLogId(metric.sessionLogId);
  newMetric.setValue(metric.value);
  newMetric.setValueDataType(metric.valueDateType);

  CreateMetric.setMetric(newMetric);

  const request = await sdkClient.query(MetricService.Create, CreateMetric);

  return request.toObject();
};

export const createOrUpdateExpectation = async (expectation) => {
  const newExpectation = new Expectation();

  const CreateExpectation = new CreateExpectationRequest();
  newExpectation.setName(expectation.name);
  newExpectation.setSessionLogId(expectation.sessionLogId);
  newExpectation.setThreshold(expectation.threshold);
  newExpectation.setValue(expectation.value);
  newExpectation.setPassed(expectation.value <= expectation.threshold);

  CreateExpectation.setExpectation(newExpectation);

  const request = await sdkClient.query(
    ExpectationService.Create,
    CreateExpectation
  );

  return request.toObject();
};

export const createMetricExpectationMutation = ({ onSuccess }) => {
  const mutation = useMutation({
    mutationFn: async (object: Expectation.AsObject | Metric.AsObject) => {
      // check if it's an expectation or metric
      if (
        // eslint-disable-next-line no-prototype-builtins
        object.hasOwnProperty('threshold') &&
        object['threshold'] !== null &&
        object['threshold'] !== undefined
      ) {
        return await createOrUpdateExpectation(object);
      } else {
        return await createOrUpdateMetric(object);
      }
    },
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      onSuccess();
    },
  });
  return mutation;
};

export const createExpectationMutation = ({ onSuccess }) => {
  const mutation = useMutation({
    mutationFn: createOrUpdateExpectation,
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      onSuccess();
    },
  });
  return mutation;
};

export const createMetricMutation = async ({ onSuccess }) => {
  const mutation = useMutation({
    mutationFn: createOrUpdateMetric,
    onError: (error) => {
      return error;
    },
    onSuccess: (data) => {
      onSuccess();
    },
  });
  return mutation;
};

export const getMetric = async (metricId) => {
  const GetMetric = new GetMetricRequest();
  GetMetric.setId(metricId);

  const request = await sdkClient.query(MetricService.Get, GetMetric);

  return request.toObject();
};

export const getExpectation = async (expectationId) => {
  const GetExpectation = new GetExpectationRequest();
  GetExpectation.setId(expectationId);

  const request = await sdkClient.query(ExpectationService.Get, GetExpectation);

  return request.toObject();
};
